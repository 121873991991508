@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-compact-content() {
  app-campaign-compact-content {
    display: grid;
    grid-template-columns: 65fr 35fr;
    grid-template-rows: minmax(0, 1fr) auto;
    grid-template-areas:
      "preview column"
      "footer footer";
    height: 100%;
    gap: 1rem;

    .compact-preview {
      grid-area: preview;
      height: 100%;
      overflow-y: auto;
      padding: 1rem;

      .preview-not-available {
        text-align: center;
        padding-top: 25%;
      }
    }

    .compact-column {
      grid-area: column;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content minmax(0, 1fr);
      grid-template-areas: "compact-products" "compact-links";
      gap: 1rem;
      padding: 1rem 1rem 0 0;
    }

    .compact-products {
      grid-area: compact-products;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .card-body {
        padding: 0;

        app-tabs-panel {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }

      app-grid {
        flex: 1;
        display: block;
        height: 18rem;
        padding: 0.5rem;

        ag-grid-angular {
          padding-bottom: 0!important;
        }
      }
      .compact-products-empty {
        flex: 1;
        display: flex;
        height: 18rem;
        justify-content: center;
        align-items: center;
      }
    } /* products */

    .additional-information {
      grid-area: compact-links;

      .card {
        height: unset;
        .card-body {
          padding: 0;
        }
      }
    }
    .additional-information-wrapper {
      display: flex;
      flex-direction: column;

      &.hidden {
        display: none;
      }
      .compact-links {
        overflow-y: auto;
      }
      .detail-items {
        .label, .value {
          font-size: 1rem;
        }
      }
      .compact-links,
      .detail-items {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem;
      }
      .compact-space {
        flex: 1;
      }
      .card {
        padding-bottom: 1rem;
      }

      .link-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        a {
          font-size: 1rem;
        }
      }

      .card-body {
        overflow-y: auto;
      }
    }

    .compact-link-space {
      grid-area: compact-space;
    }

    .compact-footer {
      grid-area: footer;
      display: flex;
      flex-direction: row;
      justify-content: end;
      border-top: 1px solid var.$color-grey-4;
      gap: 1rem;
      padding: 0.5rem;
    }
  }
}
