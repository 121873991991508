@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-suitability-review() {
  app-campaign-suitability-review {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-template-rows: auto 1fr 1fr;
    grid-template-areas:
      "toolbar    grid"
      "chart      grid"
      "categories grid";

    .toolbar {
      grid-area: toolbar;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 1.5rem;
      gap: 1rem;
      height: 61px;

      mat-button-toggle-group {
        .mat-button-toggle-label-content {
          padding: 0.25rem;
        }
        mat-pseudo-checkbox {
          display: none;
        }
      }
    }
    .pie-wrapper {
      grid-area: chart;
      padding: 1rem;
      display: flex;
      flex-direction: column;

      > app-chart {
        flex: 1;

        .chart-container {
          height: 100%;
          max-height: 100%;
        }
      }
    }
    app-grid[tableid="campaign-suitability-clients-list"],
    app-grid[tableid="campaign-suitability-portfolios-list"] {
      grid-area: grid;
    }
    app-grid[tableid="campaign-suitability-category-list"] {
      grid-area: categories;
    }
  } /* app-campaign-suitability-review */
} /* mixin */
