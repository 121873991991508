@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin product-details() {
  app-product-details {
    .product-details__top {
      display: flex;
      flex-direction: column;
      width: 100%;

      .top-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.25rem;

        .product-name {
          display: flex;
          flex-direction: row;

          app-config-icon {
            cursor: pointer;
          }
        }

        .product-badge {
          app-config-icon {
            font-size: 2rem;
            color: var.$brand-color-primary;
            width: 2rem;
          }
        }
      }

      .content-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1.25rem;

        .product-details__description {
          display: block;
          width: 75%;
          max-width: 75vw;
          max-height: 5rem;
          text-align: justify;
          text-overflow: ellipsis;
          clip-path: inset(0);
        }

        .pdf-download-container {
          width: 17%;
          display: flex;
          flex-direction: column;

          .h4 {
            margin-bottom: 12px;
            display: block;
            border-bottom: 1px solid #eee;
          }

          &__link {
            display: block;
            margin-bottom: 0.25rem;
            color: var.$accent-color;

            span.body {
              padding-left: 0.25rem;
              margin-bottom: 0px;
            }

            &:hover {
              cursor: pointer;

              span {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .product-chart-container {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      margin-bottom: 1rem;

      .h3 {
        display: block;
        text-align: center;
      }
    }

    .product__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div.performance-container {
        flex: auto;
        display: flex;
        justify-content: space-evenly;
      }
    }

    .product__country {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var.$brand-color-secondary;

      mat-icon {
        position: relative;
        top: -2px;
        margin-right: 4px;
      }

      i {
        font-style: normal;
      }
    }

    .product__currency {
      text-transform: uppercase;
      color: var.$brand-color-secondary;
    }

    .category-tags {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}
