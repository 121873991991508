@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin settings() {
  app-settings {
    .page-content-tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid var.$brand-color-secondary;
    }
    .tab-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      app-salutations,
      app-template-defaults {
        flex: 1;
      }

      &.inner-padding {
        padding: 1rem;
      }
    }

    .page-content-header {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      border-bottom: 1px solid var.$brand-color-secondary ;
    }
  }
}
