@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin filter-asset() {
  app-filter-asset {
    app-tag {
      display: flex;
      justify-content: space-between;

      &.highlight {
        .mat-icon {
          &:hover {
            color: var.$accent-color;
          }
        }
      }

      .mat-icon {
        cursor: pointer;

        &:hover {
          color: var.$state-color-error;
        }
      }
    }

    .filter-content {
      padding: 0 0.5rem;
      display: grid;
      gap: 0.5rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      grid-template-areas:
        "label  unbundled"
        "slider slider";

      .exposure-label {
        grid-area: label;
      }
      .exposure-unbundled {
        grid-area: unbundled;
      }
      .exposure-slider {
        grid-area: slider;
      }
      .exposure-label,
      .exposure-unbundled {
        width: calc(50% - 0.5rem);
        .mat-mdc-form-field-infix {
          width: 143px;
        }
      }
    }

    .filter-content,
    .exposure-slider {
      margin-top: 0.25rem;
    }

    .hidden {
      display: none;
    }
  }
}
