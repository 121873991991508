@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;

@mixin image-gallery() {
  app-image-gallery {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;

    #image-gallery-container {
      flex: 1;
      padding: 1rem;
      overflow-y: auto;

      .image-gallery-list {
        width: 100%;
        height: 100%;

        &.row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0.5rem;
          justify-content: space-between;
        }

        .image {
          position: relative;
          padding: 0.5rem;
          border: 1px solid lightgray;
          border-radius: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 240px;
          height: 240px;
          img {
            max-width: calc(240px - 1rem);
            max-height: calc(240px - 1rem);

            &.closed {
              filter: grayscale(1);
            }
          }

          .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 240px;
            height: 240px;
            border-radius: 0.5rem;
            cursor: pointer;

            &:hover {
              background-color: rgba(255 , 255, 255, 0.4) !important;
            }
            &.closed {
              background-color: rgba(64 , 64, 64, 0.1);
            }
          }
        }
      }
    }
    #image-gallery-toolbar {
      display: none;
      flex-direction: row;
      padding: 0.5rem 0.5rem 5rem;
    }
    .image-gallery-image {
      box-shadow: var.$box-shadow;
      height: 100%;
      width: 100%;
      aspect-ratio: 16 / 9;
      background-position: center 45%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
