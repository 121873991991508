@use 'src/assets/style/_variables.scss' as var;

@mixin card() {
  app-card {
    &.no-header {
      .card-header {
        display: none;
      }
    }

    .card {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
      background-color: var.$color-white;
      color: var.$color-black-1;
      border-radius: 0.5rem;
      box-shadow: var.$box-shadow;

      &.active {
        background-color: var.$color-grey-4;
      }

      .card-header-container,
      .card-body {
        padding: 1.1rem 1.5rem;
      }

      .card-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var.$color-grey-5;
        gap: 1.5rem;

        .card-header {
          max-width: calc(100% - 3rem);
          height: 1.5rem;

          div[card-header] {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
        }

        .card-actions {
          display: flex;
          z-index: 2;
          align-items: center;
          gap: 0.5rem;
          font-size: 24px;

          app-config-icon {
            cursor: pointer;
            color: var.$brand-color-primary;
          }
        }
      }

      .card-body {
        flex: 1;
        z-index: 1;

        div[card-body] {
          height: 100%;
        }
      }
    }

    .spinner-container {
      position: absolute;
      top: -0.5rem;
      bottom: -0.5rem;
      right: -0.5rem;
      left: -0.5rem;
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      background-color: adjust-color(var.$brand-color-primary, $alpha: -0.7);
      z-index: 10;
    }
  }

  .update-flash {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 2;
    opacity: 0.3;
    animation: blinker 0.7s ease-in;
  }

  @keyframes blinker {
    50% {
      background-color: var.$state-color-warning;
    }
  }
}
