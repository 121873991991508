@use 'src/assets/style/_variables.scss' as var;

@mixin hub-languages() {
  app-hub-languages {
    padding: 0.5rem;

    &[nopadding] {
      padding: 0;
    }
    .languages-form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;

      > * {
        width: calc(32% - 0.5rem);
      }
    }
  }
}
