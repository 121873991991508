@use 'src/assets/style/_variables.scss' as var;

@mixin salutation-details() {
  app-salutation-details {
    width: 100%;
    form {
      padding: 5px;
    }
    ul {
      list-style: none;
      gap: 0.25rem;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;
      padding: 1rem;

      .chip {
        border-radius: 1rem;
      }
    }
    .row {
      gap: 10px;
    }
    .row > * {
      flex-grow: 1;
    }
    textarea {
      min-height: 10rem;
    }
    fieldset {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
    .fr-deletable {
      background-color: beige;
      color: var.$brand-color-secondary;
    }
  }
}
