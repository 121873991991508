@use 'src/assets/style/_variables.scss' as var;

@mixin disclaimer() {
  app-disclaimer, app-disclaimer-assignment {
    position: relative;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "fallback    assignments"
      "disclaimers assignments";

    .disclaimer-toolbar {
      position: absolute;
      right: 1rem;
      top: -3rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }

    app-hub-languages {
      grid-area: fallback;
    }
    .disclaimer-grid {
      grid-area: disclaimers;
    }
    .disclaimer-assignments {
      grid-area: assignments;
    }
    app-grid {
      flex: 1;
    }
    .inner-padding {
      padding: 0.5rem;
    }
    .disclaimer-side {
      display: none;
    }
  }

  app-disclaimer-dialog {
    .content-header {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;

      h4 {
        flex: 1;
      }
    }
    .contents {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-height: 22rem;

      .modal-form {
        overflow-y: auto;
      }

      .row.row-single {
        position: relative;

        app-config-icon {
          color: red;
          position: absolute;
          top: 1rem;
          right: 1rem;
          cursor: pointer;
        }
      }
    }

  }
}
