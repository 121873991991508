@use 'src/assets/style/_variables.scss' as var;

@mixin home() {
  app-home {
    .home-container {
      flex: 1;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 40%;

      .home-action-grid {
        display: flex;
        flex-direction: column;
        flex: 1;

        .ag-root-wrapper-body {
          border: unset;
        }
      }

      .home-extension {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-gap: 1rem;

        .home-best-actions .card-header app-config-icon {
            color: var.$brand-color-primary;
        }
      }
    }

    .home-warning {
      padding: 1rem 1.5rem;
    }
    .home-header {
      padding: 1.5rem;
      border-bottom: 1px solid var.$color-grey-6;
    }
  }
}
