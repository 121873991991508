@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-portfolio-list() {
  app-campaign-portfolio-list {
    .ag-cell[col-id="hasContentOverride"] {
      padding: 0;
    }
    .grid-cell-custom-content {
      .label {
        margin-left: 0.3rem;
      }
      app-config-icon.info-icon {
        cursor: pointer;
      }
      .custom-content-cell {
        display: flex;
        flex-direction: row;
      }
    }

    .campaign-portfolio-list-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;

      .table-selection-container {
        display: flex;
        align-items: center;
        min-height: 3.125rem;
        max-height: 3.125rem;
        padding: 0 1.5rem 0 0.4rem;
        border-bottom: 1px solid var.$color-grey-6;
      }

      app-grid {
        flex: auto;
      }

      .ag-cell[col-id="portfolioNumber"] {
        .label-icon {
          display: inline-flex;
          flex-direction: row;
          gap: 0.5rem;
        }

        app-config-icon {
          cursor: pointer;
          color: var.$state-color-success;
        }
      }
    }

    div[col-id='combinedSuitabilityState'] {
      & > .ag-cell-wrapper {
        height: 100%;
      }
      app-config-icon {
        cursor: pointer;
      }
    }

    div[col-id='portfolioNumber'] {
      span.label {
        padding-top: 1px;
      }
    }

    .grid-cell-action-language {
      gap: 1rem;
    }
    .ag-cell-wrapper {
      width: 100%;
    }

    .spinner-container {
      position: relative;

      mat-spinner {
        position: absolute;
        left: 0.4rem;
        top: -2.8rem;
      }
    }
  }
}
