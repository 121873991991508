@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;

@mixin settings-tab-picker() {
  app-settings-tab-picker {

  }
  .mat-mdc-menu-panel.settings-menu {
    max-width: unset;
  }
  .app-settings-tab-picker-popup-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
  }
  .app-settings-tab-picker-popup-column {
    display: flex;
    flex-direction: column;

    .popup-title {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: normal;
      border-bottom: 1px solid var.$state-color-disable;
    }
  }
}
