@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-collection-available-entries() {
  app-campaign-collection-available-entries {
    max-height: 91%;
    .panel-main {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      .mat-expansion-panel-content, .mat-expansion-panel-body {
        height: 95%;
      }
    }

    mat-dialog-actions {
      padding: 1.25rem 1.5rem;
      border-top: 1px solid var.$color-grey-5;
    }

    .disabled-pointer {
      pointer-events: none;
    }
  }
}
