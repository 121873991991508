@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin app() {
  //******************************************************************
  // General helper classes
  //******************************************************************
  .page-content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .aspark-container-padding {
    padding: 1.25rem 1.5rem;
  }

  .basic-flex {
    display: flex;
    gap: 1.25rem;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .aspect {
    width: 100%;

    &::before {
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
    }

    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .aspect-16-9::before {
    padding-bottom: calc(100% / (16 / 9));
  }

  .preview {
    border-radius: 0.25rem;
    background-color: var.$color-grey-6;
    overflow: hidden;
    position: relative;
    max-height: 100%;

    .image-placeholder {
      .mat-icon {
        width: 120px;
        height: 120px;
        font-size: 1.5rem;
        color: var.$color-grey-4;
        padding-top: 3rem;
      }
    }

    img {
      width: 100%;
    }

    .mat-icon,
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    button {
      position: absolute;
      bottom: 6px;
    }
    button.edit-image {
      right: 6px;
    }
    button.delete-image {
      right: 58px;
    }

    app-config-icon {
      font-size: 1.5rem;
    }
  }

  .display-block {
    display: block !important;
  }

  .href-link {
    color: var.$brand-color-primary;
    text-decoration: none;

    &:hover {
      color: var.$brand-color-primary;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .link-on-label {
    color: var.$brand-color-primary;
    text-decoration: none;
    cursor: pointer;
  }

  .font-small {
    font-size: 0.875rem;
  }

  @keyframes inner-spinner {
    to {
      transform: rotate(360deg);
    }
  }

  button.inner-spinner {
    background-color: var.$color-grey-6 !important;
  }

  .inner-spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid var.$color-white;
    border-top-color: var.$color-black-1;
    animation: inner-spinner 0.8s linear infinite;
    display: inline-block;
  }

  .hot-toggle {
    position: relative;
    top: 50%;
    margin-top: -1rem;
    label {
      justify-content: center;
      height: 100%;
    }
  }

  //******************************************************************
  // Ngslider styles
  //******************************************************************
  .ngx-slider[disabled] {
    opacity: 0.7;
  }
  .ngx-slider {
    span {
      outline: none;
    }

    &.primary {
      .ngx-slider-pointer {
        background-color: var.$brand-color-primary;
      }

      .ngx-slider-selection {
        background-color: var.$color-grey-4;
      }
    }

    .ngx-slider-pointer {
      &.ngx-slider-active:after {
        background-color: var.$color-white;
      }
    }

    .ngx-slider-bubble {
      font-size: 0.9rem;
      padding-bottom: 5px;
    }
  }

  //******************************************************************
  // Material styles
  //******************************************************************
  .mat-form-field-standard {
    width: 100%;
  }
  mat-form-field.mat-form-field-small {
    --mat-form-field-container-height: 2.2rem;
    --mat-form-field-container-vertical-padding: 0.25rem;
  }
  .mat-form-field-small {
    --mat-form-field-font-size: 0.85rem;
    width: 100%;
    --mat-form-field-container-text-size: var(--mat-form-field-font-size);
    --mdc-filled-text-field-label-text-size: var(--mat-form-field-font-size);
    --mat-select-trigger-text-size: var(--mat-form-field-font-size);
  }

  mat-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .odd {
      background-color: var.$color-grey-6;
    }
  }
  mat-radio-button {
    width: 100%;
  }
  .mat-menu-item-flex .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .mdc-button__label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: none;
  }

  .mat-button-toggle-button {
    &[aria-pressed=true] {
      font-weight: bold;
    }
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: var.$brand-color-primary;
  }

  // fixes an issue on language select-option
  .language-option {
    // hide the selected checkbox
    .mat-pseudo-checkbox {
      display: none;
    }
  }

  //******************************************************************
  // Overwrite default browser styles
  //******************************************************************
  // remove arrow/spinner from input
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  ::-webkit-scrollbar-track {
    background-color: var.$color-sub-white;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var.$color-grey-5;
  }

  ::-moz-selection {
    color: var.$color-black-1;
    background: var.$color-grey-4;
  }

  ::selection {
    color: var.$color-black-1;
    background: var.$color-grey-4;
  }

  //******************************************************************
  // Modal Styles
  //******************************************************************

  .modal-form {
    textarea {
      height: 100px;
    }

    .row-single {
      > mat-form-field {
        width: 100%;
      }
    }

    .row-double {
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;

      > * {
        flex: 1;

        &.file {
          input[type='file'] {
            visibility: hidden;
          }

          app-config-icon[iconidentifier='delete_forever'] {
            cursor: pointer;
          }

          .btn-container {
            display: flex;
            align-items: center;
            gap: 2rem;
          }

          .file-status {
            display: flex;
            align-items: center;
            font-size: 2rem;

            .download-link {
              text-decoration: none;
              color: var.$brand-color-primary;
            }
          }
        }
      }
    }

    ngx-extended-pdf-viewer {
      display: block;
      width: 100%;
      height: 300px;
    }
  }
  // full-width grids
  .grid-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .grid-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
      font-size: 24px;
      padding-right: 1rem;
      min-height: 2.5rem; // 24px + 1rem padding
      height: 2.5rem;
      padding-bottom: 1rem;
    }

    app-config-icon {
      cursor: pointer;
      color: var.$brand-color-primary;
    }
  }

  //******************************************************************
  // AG Grid
  //******************************************************************
  .ag-theme-material {
    .ag-checkbox-input-wrapper.ag-checked::after, .ag-checkbox-input-wrapper.ag-indeterminate::after {
      color: var.$brand-color-primary;
    }
  }

  .overview-toolbar {
    display: none;
    flex-direction: row-reverse;
    gap: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-right: 2rem;
    width: 50%;
    height: 4.5rem;
    position: fixed;
    right: 85px;
    top: 3px;
    z-index: 2;

    &.visible {
      display: flex;
    }
  }

  .ag-root-wrapper-body {
    border: 1px solid var(--ag-border-color);
  }

  //******************************************************************
  // Navigation panel styles
  //******************************************************************
  .nav-panel-container {
    display: flex;

    .nav-panel {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var.$brand-color-primary;
      gap: 1rem;
      padding: 1rem 1.5rem;
      z-index: 1;
      border-top: 1px solid rgb(0 0 0 / 12%);
      border-left: 1px solid rgb(0 0 0 / 12%);
      background-color: var.$color-grey-6;

      &:first-child {
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        border-left: none;
        border-top-left-radius: 0.5rem;
      }

      &:last-child {
        border-right: none;
        border-right: 1px solid rgb(0 0 0 / 12%);
      }

      &.active {
        background: var.$color-white;
        color: var.$accent-color;
      }

      &.disabled {
        pointer-events: none;
        color: var.$color-grey-4;
      }

      &:hover {
        color: var.$accent-color;
        cursor: pointer;
      }

      &.overview {
        height: 3rem;
        margin-top: 0.5rem;
        padding: 0.5rem 1.5rem;
        color: var.$accent-color;
        background: var.$color-white;
        max-width: 250px;
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  //******************************************************************
  // Typography styles
  //******************************************************************
  .icon-large {
    font-size: 2rem;
    width: 2rem;
  }

  .h1 {
    @include mat.m2-typography-level(typo.$config, 'headline-1');
  }

  .h2 {
    @include mat.m2-typography-level(typo.$config, 'headline-2');
  }

  .h3 {
    @include mat.m2-typography-level(typo.$config, 'headline-3');
  }

  .h4 {
    @include mat.m2-typography-level(typo.$config, 'headline-4');
  }

  .h5 {
    @include mat.m2-typography-level(typo.$config, 'headline-5');
  }

  .h6 {
    @include mat.m2-typography-level(typo.$config, 'headline-6');
  }

  .italic {
    font-style: italic;
  }

  .body-1 {
    @include mat.m2-typography-level(typo.$config, 'body-1');
  }

  .body-2 {
    @include mat.m2-typography-level(typo.$config, 'body-2');
  }

  .body-3 {
    @include mat.m2-typography-level(typo.$config, 'body-3');
  }

  .color-primary {
    color: var.$brand-color-primary !important;
  }

  .color-error {
    color: var.$state-color-error !important;
  }

  .color-success {
    color: var.$state-color-success !important;
  }

  .color-warning {
    color: var.$state-color-warning !important;
  }

  .color-white {
    color: var.$color-white !important;
  }

  //******************************************************************
  // Selected-Available-List styles
  //******************************************************************
  .selected-available-container {
    height: 100%;
    padding: 1.25rem 1.5rem;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'selected actions available'
      'selected actions available';

    .actions {
      grid-area: actions;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
      }
      .mat-icon {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .selected {
      grid-area: selected;
      height: 100%;
    }

    .available {
      grid-area: available;
      height: 100%;
    }
  }
  /*****************************************/
  /*  hub-menu                             */
  /*****************************************/
  .colored-menu-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    gap: 1.25rem;
    cursor: pointer;
    border: 1px solid var.$brand-color-primary;
    border-radius: 4px;
    background-color: var.$color-white;

    app-config-icon {
      cursor: pointer;
      color: var.$brand-color-primary;
    }
  }
}
