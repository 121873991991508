@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin filter-range-weight() {
  app-filter-range-weight {
    app-tag {
      display: flex;
      justify-content: space-between;

      &.highlight {
        .mat-icon {
          &:hover {
            color: var.$accent-color;
          }
        }
      }

      .mat-icon {
        cursor: pointer;

        &:hover {
          color: var.$state-color-error;
        }
      }
    }

    .filter-content {
      padding: 0 0.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "toggle     unbundled"
        "slider     slider"
        "weight     .";

      .exposure-label {
        grid-area: toggle;
        align-content: center;
      }
      .exposure-weight {
        grid-area: weight;
        mat-form-field {
          width: 100%;
        }
      }
      .exposure-slider {
        grid-area: slider;
      }
      .exposure-unbundled {
        grid-area: unbundled;
        mat-form-field {
          width: 100%;
        }
      }
    }

    .filter-content,
    .exposure-slider {
      margin-top: 0.25rem;
    }

    .mat-mdc-slider.mdc-slider {
      width: 100%;
    }
  }
}
