@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin filter() {
  app-filter {
    .filter-preference {
      cursor: pointer;
      color: var.$color-grey-5;

      &.active {
        color: var.$brand-color-primary;
      }

      &:hover {
        color: var.$accent-color;
      }
    }

    app-config-icon.icon-button {
      cursor: pointer;
    }

    .mat-mdc-slider.mdc-slider {
      width: 100%;
    }

    .filter-content {
      display: flex;

      > mat-form-field + mat-form-field {
        margin-left: 2rem;
      }
    }

    .error-content {
      margin-bottom: 1rem;
    }

    .margin-top-base-2 {
      margin-top: 2rem;
    }

    button.multi-select-btn.active,
    button.multi-select-btn:hover {
      background-color: var.$accent-color !important;
      color: var.$color-black-1 !important;
      cursor: pointer;
    }

    button + button {
      margin-left: 12px;
    }

    .filter-label.body {
      margin-bottom: 0px;
    }

    mat-form-field {
      &.min-max-form-field {
        width: calc(50% - 1rem);
      }

      .mat-form-field-suffix,
      .mat-form-field-prefix {
        padding-right: 0.5rem;
        font-size: 0.9rem;
        color: var.$color-black-2;
      }
    }
  }
  .select-search-field {
    width: 100%;

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          width: unset; // otherwise placeholder is not fully visible for german
        }
      }
    }

    input {
      padding-left: 1rem;
    }

    .mat-form-field-label-wrapper {
      label {
        padding-left: 1rem;
      }
    }
  }
  .read-only-tooltip { // used to style read only tooltip -> matTooltipClass
    top: -1.375rem;
  }

  .range-slider {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    padding-top: 1rem;
  }
  // align value with prefix mat-form-field input
  .mat-mdc-form-field-infix {
    align-content: center;
  }
}
