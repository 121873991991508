@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin users-settings() {
  app-user-settings {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.25rem;
    flex: 1;

    .roles-table {
      grid-area: roles;
      @include mat.m2-typography-level(typo.$config, 'body-2');
      border-collapse: collapse;

      tr {
        height: 36px;

        td,
        th {
          padding: 0.5rem 2rem;
          border-right: 1px solid var.$color-grey-5;
        }

        td:last-child,
        th:last-child {
          border-right: none;
        }
      }

      thead {
        border-bottom: 1px solid var.$color-grey-5;
      }

      tbody td {
        text-align: center;
      }
    }

    app-grid {
      flex: 1;
    }
  }
}
