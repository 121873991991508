@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-catalogue {
  app-campaign-catalogue {
    position: relative;

    .page-content-container {
      position: relative;
    }

    .campaign-catalogue-toolbar {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      display: flex;
      gap: 0.5rem;
    }
  }
}
