@use 'src/assets/style/_variables.scss' as var;

@mixin campaign-compact-send() {
  app-campaign-compact-send {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "toolbar-container toolbar-container toolbar-container"
        "selected-available-container selected-available-container selected-available-container"
        "footer footer footer";

    .toolbar-container {
      grid-area: toolbar-container;
      display: grid;
      grid-template-columns: subgrid;
      grid-template-rows: auto;
      font-size: 0.875rem;
      padding: 0.75rem 1.5rem 0.75rem 1rem;

      .toolbar-right {
        display: flex;
        justify-content: flex-end;
        align-items: start;
        padding-top: 0.5rem;
      }

      .available-toolbar {
        display: grid;
        grid-template-columns: 30rem auto;
        grid-template-rows: 1fr;
        width: 100%;

        .href-link-container {
          text-align: right;
          display: flex;
          align-items: end;
          justify-content: end;
          padding-bottom: 0.5rem;
        }

        .dropdown-selection-bar {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          app-dropdown-selection-bar {
            width: 100%;
            border: none;
            padding: unset;

            .mat-mdc-form-field-infix {
              align-content: center;
            }
          }
        }
      }
    }

    .selected-available-container {
      grid-area: selected-available-container;
      grid-template-columns: subgrid;
      padding: 0.25rem 1rem 1rem; // top left/right bottom
      max-height: 42rem;

      app-card .card .card-body {
        padding-top: 0.5rem;
      }
    }

    .select-btn {
      animation: pulse 650ms infinite ease-in-out alternate;
    }
    @keyframes pulse {
      from {
        transform: scale(1.0);
      }
      to {
        transform: scale(1.100);
      }
    }

    .compact-footer {
      grid-area: footer;
      display: flex;
      flex-direction: row;
      justify-content: end;
      border-top: 1px solid var.$color-grey-4;
      gap: 1rem;
      padding: 0.5rem;
    }

    .ag-header-cell.header-checkbox {
      padding-left: 1.1rem;
    }
  }
  .ag-cell[col-id="portfolio.number"] {
    .label-icon {
      display: inline-flex;
      flex-direction: row;
      gap: 0.5rem;
    }
    app-config-icon {
      cursor: pointer;
      color: var.$state-color-success;
    }
  }
  .ag-cell.cell-custom-content app-config-icon {
    color: var.$state-color-success !important;
  }
}
