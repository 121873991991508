@use 'src/assets/style/_variables.scss' as var;

@mixin story-overview() {
  app-story-overview {
    .overview-container-parent {
      display: block;
      overflow: auto;
      height: 100%;
    }
    .content-container {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 1rem;
      padding: 1rem 1rem;
    }

    .overview-details-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      height: 100%;
      gap: 1rem;
      padding: 1rem 1rem;

      .details-column {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto 1fr;
        height: 100%;
        width: 100%;
        gap: 1rem;
      }
      .charts-column {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 45vh 45vh;
        height: 100%;
        width: 100%;
        gap: 1rem;
      }
      app-story-overview-details {
      }
      app-risk-return-chart-card {
      }
      .stats-filters-wrapper {
        padding-bottom: 1rem;
      }
      app-additional-charts-card {
        padding-bottom: 1rem;
      }
    }

    .tab-content-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      app-portfolio-list,
      app-intermediary-list {
        flex: 1;
      }
    }

    .overview-container, .overview-details-container {
      app-statistics {
        margin-bottom: 1rem;
      }
    }

    .product-list {
      grid-column: span 2;
    }

    .story-contents {
      grid-column: span 4;
    }

    .tooltipfix {
      > * {
        width: 100%;
        height: 100%;
      }
    }

    .page-content-container {
      position: relative;
      app-story-overview-toolbar > div {
        justify-content: end;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
