@use 'src/assets/style/_variables.scss' as var;

@mixin grid-filter-item-radio() {
  app-grid-filter-item-radio {
    mat-form-field {
      width: 100%;
    }
    mat-checkbox {
      span {
        display: flex;
        align-items: center;
      }
    }
    .grid-filter-item {
      .grid-filter-item-content {
        overflow-y: auto;
        padding-bottom: 4px;
        .mdc-text-field.mdc-text-field--outlined {
          margin-top: 4px;
          margin-bottom: -1rem;
        }
        .grid-filter-item-option-list {
          display: flex;
          flex-direction: column;
          max-height: 15rem;
        }
      }
    }
  }
}
