@mixin story-catalogue() {
  app-story-catalogue {
    display: flex;
    flex-direction: column;
    height: 100%;

    app-card {
      flex-grow: 2;
    }

    .page-content-container {
      position: relative;

      button.add-story {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
      }
    }
  }
}
