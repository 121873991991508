@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/mixins.scss' as mixin;

@mixin playground() {
  app-playground {
    .playground-container {
      height: 100%;
      padding: 1.25rem 1.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.25rem;

      .playground-helper {
        display: flex;
        flex-direction: column;

        app-filter-active-tags {
          display: block;
          margin-bottom: 2rem;
        }

        app-filter-active-tags {
          > app-card:first-child {
            .card {
              border-top-left-radius: 0;
            }
          }
        }
      }

      .playground-charts {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        min-height: 0;

        app-risk-return-chart-card {
          min-height: 45%;
          max-height: 45%;
        }
        app-additional-charts-card {
          min-height: 45%;
          max-height: 45%;
        }
      }
    }

    .tab-content-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      app-portfolio-list,
      app-intermediary-list {
        flex: 1;
      }
    }
  }
}
