@use 'src/assets/style/_variables.scss' as var;

@mixin overview-allowed-users() {
  app-overview-allowed-users {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;

    .allowed-users-grid-container {
      flex: 1;
      display: flex;
      gap: 1.25rem;

      > app-card {
        flex: 1;
      }
    }
  }
}
