@use '@angular/material' as mat;
@use '_variables.scss' as var;
@use '_typography.scss' as typo;
// import app
@import '../../app/app.component.scss';
// feature modules
@import 'core.styles.scss';
@import 'monitoring.styles.scss';
@import 'shared.styles.scss';
@import 'product.styles';
@import 'simulator.styles.scss';
@import 'story.styles.scss';
@import 'objects.styles.scss';
@import 'campaign.styles.scss';
@import 'portfolio.styles.scss';
@import 'client.styles.scss';
@import 'my-settings.styles';
@import 'employee.styles.scss';
@import 'intermediary.styles.scss';
@import 'distributor.styles.scss';
// include core once
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$aspark-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $brand-palette-primary,
      accent: $accent-palette,
      warn: $state-palette-error,
    ),
    typography: typo.$config,
    density: 0,
  )
);

// theme class
.aspark-light {
  // include theme
  @include mat.all-component-themes($aspark-theme);
  // include app styles
  @include app();
  // feature modules
  @include coreModule();
  @include sharedModule();
  @include productModule();
  @include monitoringModule();
  @include simulatorModule();
  @include storyModule();
  @include objectsModule();
  @include campaignModule();
  @include portfolioModule();
  @include clientModule();
  @include mySettingsModule();
  @include employeeModule();
  @include intermediaryModule();
  @include distributorModule();
}
